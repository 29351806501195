const source = [
            <p>СП <strong>350.1326000.2018.</strong> «Нормы технологического проектирования морских портов»</p>,
            <p>СП <strong>38.13330.2018</strong> «Нагрузки и воздействия на гидротехнические сооружения (волновые, ледовые и от судов)»</p>,
            <p>Приказ Минтруда № <strong>343н</strong> от <strong>15.06.2020</strong> «Об утверждении правил по охране труда в морских и речных портах»</p>,
            <p>Нормы технологического проектирования портов на внутренних водных путях</p>,
            <p>Технический регламент о безопасности объектов внутреннего водного транспорта</p>,
            <p>Технический регламент о безопасности объектов морского транспорта</p>,
            <p>ГОСТ Р <strong>54523-2011</strong> «Портовые гидротехнические сооружения. Правила обследования и мониторинга технического состояния»</p>,
            <p>ГОСТ Р <strong>55561-2013</strong> «Внутренний водный транспорт. Портовые гидротехнические сооружения Требования безопасности»</p>,
            <p>СП <strong>58.13330.2019</strong> «Гидротехнические сооружения. Основные положения»</p>,
]
export default source;
